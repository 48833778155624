import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-grid-system';
import { HeroPatients } from 'components/hero';
import HcpIsi from 'components/layout/layout-blocks/isi/isi-contents/HcpIsi';

// import page style
import './important-safety-information.scss';

const ImportantInformationSystem = () => {
	return (
		<Layout indication='patient' className='page-patient-isi' noShowStickyIsi>
			<Seo pageTitle='Indication/Important Safety Information' />
			<div className='body-content-container'>
				<div className='body-content'>
					<HeroPatients heroImgAlt='page hero' className='sub-page-hero'>
						<h1>Important Safety Information/Indication</h1>
					</HeroPatients>
					<Row>
						<Col xs={12}>
							<div className='isi'>
								<div className='isi-content-wrapper'>
									<div className='isi-content'>
										<HcpIsi />
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</Layout>
	);
};

export default ImportantInformationSystem;
